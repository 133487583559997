.navbar-toggler{
    border: none;
  }
  .dropdown-item:active{
    background-color: #FF6A00;
    color: white;
    
  }

/* body{
  background-color: #242B2E;
  color: white;
} */
  /* .nav-links{
    z-index: 99;
    position: sticky;
  } */
  .dropdown-menu{
    border: #FF6A00 1px solid;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  }
  /* codepen */
  
  .sec::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: 0px;
    content: '';
    background: #FF6A00;
    animation: sec 1500ms infinite;
  }
  .NotFound{
    height: 600px;

    background-image: url("./Assets//404.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  /* .prize{
    margin-top:100px;
  } */
  .c-a{
  text-decoration: none;
  color:#0D0D0D;
  }
  .c-link{
    text-decoration: none;
  }
  .c-a:hover{
    color: #0D0D0D;
  }
  .rule-img{
    border-radius: 250px;
  }
  .f-link{
    text-decoration: none;
  }
  .f-link:hover{
    color: #0D0D0D;
  }

  .zoom {
    transition: transform .2s; 
  }
  
  .zoom:hover {
    transform: scale(1.1);
  }
  .award-img{
    height:100px
  }
  body {
    scroll-snap-stop: always;
}
/* .spon{
  margin-bottom:100px;
} */